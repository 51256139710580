@font-face {
  font-family: 'DIN_2014';
  font-style: normal;
  font-weight: 700;
  src: url(./assets/fonts/DIN_2014/dpt75.woff2) format('woff2');
}
@font-face {
  font-family: 'Pragmatica';
  font-style: normal;
  font-weight: 800;
  src: url(./assets/fonts/Pragmatica/prg85.woff2) format('woff2');
}
@font-face {
  font-family: 'Pragmatica';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/Pragmatica/prg45.woff2) format('woff2');
}
* {
  padding: 0;
  margin: 0;
}
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
