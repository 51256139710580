.modal-product-detail--container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #FFFFFF;
    width: 100vw;
    height: 100vh;
}

.modal-product-detail--header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px 48px;
    gap: 48px;
    background: #FFD760;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.modal-product-detail--header-label {
    font-family: 'DIN 2014', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #000000;
}

.modal-product-detail--body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 100px;
}

.modal-product-detail--body-title {
    font-family: 'DIN 2014', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #424242;
    margin: 30px 0;
}