.modal-hose-detail--body-table {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    width: 100%;
}

.modal-hose-detail--body-column {
    display: flex;
    flex-direction: column;
    width: 100%
}

.modal-hose-detail--body-column-list {
    list-style: none;
}

.modal-hose-detail--body-column--row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
}

.modal-hose-detail--body-column--row-content {
    width: 50%;
    margin-right: 20px;
}

.modal-hose-detail--body-column--row-tile {
    font-family: 'DIN 2014', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #424242;
}

.modal-hose-detail--body-column--row-value {
    font-family: 'DIN 2014', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #919191;
}